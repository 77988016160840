
import { defineComponent, onMounted, computed } from 'vue'
import { useHead } from '@vueuse/head'
import { useTenant } from '@/core'
import { useI18n } from 'vue-i18n'
import Topbar from '@/components/Topbar.vue'
import ChapterCard from '@/components/ProgressCard.vue'
import useChapters from '@/composables/useChapters'
import BottomGradient from '@/components/BottomGradient.vue'
export default defineComponent({
  components: {
    Topbar,
    ChapterCard,
    BottomGradient,
  },
  setup() {
    const { tenant } = useTenant()
    const { t } = useI18n()
    const { chapters, updateProgressStatus } = useChapters()

    useHead({
      title: computed(() => `${t('HOME_COURSE')} - ${tenant.value.name}`),
    })

    onMounted(() => {
      updateProgressStatus()
    })
    return { chapters }
  },
})
