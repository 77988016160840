<template>
  <Topbar back-url="/" back-icon="back" />
  <div class="v-chapters">
    <div class="v-chapters__info">
      <div class="v-chapters__info__title">{{ $t('HOME_COURSE') }}</div>
    </div>
    <div class="v-chapters__list">
      <div v-for="chapter of chapters" :key="chapter.id">
        <ChapterCard
          :element="chapter"
          :completed="chapter.lessonsCompleted"
          :total="chapter.lessonsNum"
          target="chapter"
        />
      </div>
    </div>
    <BottomGradient />
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, computed } from 'vue'
import { useHead } from '@vueuse/head'
import { useTenant } from '@/core'
import { useI18n } from 'vue-i18n'
import Topbar from '@/components/Topbar.vue'
import ChapterCard from '@/components/ProgressCard.vue'
import useChapters from '@/composables/useChapters'
import BottomGradient from '@/components/BottomGradient.vue'
export default defineComponent({
  components: {
    Topbar,
    ChapterCard,
    BottomGradient,
  },
  setup() {
    const { tenant } = useTenant()
    const { t } = useI18n()
    const { chapters, updateProgressStatus } = useChapters()

    useHead({
      title: computed(() => `${t('HOME_COURSE')} - ${tenant.value.name}`),
    })

    onMounted(() => {
      updateProgressStatus()
    })
    return { chapters }
  },
})
</script>
<style lang="scss">
.v-chapters {
  min-height: 100vh;
  padding: rem(16px);
  padding-top: rem($topbar-height);
  box-shadow: 0 -0.25rem 0.5rem $app-container-outline;

  &__info {
    padding: rem(16px);

    &__title {
      margin-top: rem(16px);
      font-size: rem(32px);
      color: $course-card-title;
      font-weight: 700;
      padding: 0 rem(10px);
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
</style>
